$primary: #273a55;
$light: #7A93AC;
$dark: #617073;
$ocre: #F8CBAD;

footer {
    position: relative;
    display: grid;
    width: 100%;
    z-index: 1;
    background-color: $primary;
    color: #fff;
    padding: 2rem var(--side-padding);
    p {
        font-size: 1em;
    }
    a {
        color: #fff;
    }
}

.mentions {
    font-weight: 600;
    display: inline-block;
    margin-left: 2rem;
    @media (max-width: 680px) {
        display: block;
        margin-left: 0;
    }
}

.footerLink {
    position: inherit;
    display: content;
    margin-bottom: 1rem;
    svg {
        height: 4rem;
        width: auto;
    }
}