$primary: #273a55;
$light: #7A93AC;
$dark: #617073;
$ocre: #F8CBAD;

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(($height / $width) * 100%);
    }
    > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
  }

  @mixin transition($transition) {
    -moz-transition: $transition $animation-duration ease-in-out;
    -o-transition: $transition $animation-duration ease-in-out;
    -ms-transform: $transition $animation-duration ease-in-out;
    -webkit-transform: $transition $animation-duration ease-in-out;
    transition: $transition $animation-duration ease-in-out;
  }
  
  $animation-duration: 0.35s;

#page {
    aside, main {
        position: relative;
        float: left;
        width: 50%;
    }
    aside {
        transform: translateX(-100%);
    }

    h1 {
        margin: 0 0 2rem;
        font-size: 2em;
        text-align: center;
    }
}

#page:not([class]) { // home
    
}   

.navigation-home {
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 680px) {
        grid-template-columns: 1fr;
      }
}

.navigation-big {
    grid-template-columns: 1fr 1fr;
}

.navigation {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.plus {
    position: absolute;
    z-index: 9;
    bottom: 2rem;
    left: 50%;
    @include transform(translateX(-50%));
    font-family: "Butler";
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    background: none;
    border: none;
    color: #FFF;
    &:hover {
        span {
            @include transform(scale(1.2));
        }
    }
    span {
        @include transition(all)
        
    }
    span.center {
        display: block;
        padding: 1rem;
        border: 2px solid #fff;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        border-radius: 100%;
        margin: 1rem auto 0;
        img {
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

.plusLink {
    font-family: "Butler";
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    margin-top: calc(3rem - 1em);
    position: relative;
    display: table;
    width: auto;
    @media (max-width: 680px) {
        margin-top: calc(2rem - 1em);
    }
    &:hover {
        span {
            @include transform(translate(100%, -50%) scale(1.2));
        }
    }
    span {
        display: inline-block;
        padding: 1rem;
        border: 2px solid #000;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        right: -2rem;
        @include transition(all);
        img {
            position: absolute!important;
            width: 1rem!important;
            height: 1rem;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

.missing-field {
  border: 2px solid red;
}

.swiper, .hero {
    position: relative;
    height: 100vh;
    background: black;
    width: 100%;
    margin-top: -6rem;
    background-size: cover;
    background-position: center;
    background-color: #273a55;
    // filter: grayscale(55%) contrast(1.1);
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    .title-container {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0;
        @include transform(translateY(calc(-50% - 6rem)));
        width: 100%;
        h1 {
            color: #fff;
            width: 100%;
            margin-bottom: 0 !important;
            margin-top: -3rem!important;
        }
        h1 {
            font-size: 3.875em!important;
            line-height: normal;
    
            span {
                display: block;
                font-weight: 600;
                font-size:2rem;
                font-family: Arial, sans-serif;
            }
        }
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }
}

.hero {
    margin-bottom: 4rem;
    h1 {
        font-size: 3.875em!important;
        @media (max-width: 680px) {
            font-size: 2.875em!important;
        }
        span {
            display: block;
            font-weight: 600;
            font-size:2rem;
            line-height: 3rem;
        }
    }
}

section {
    padding: 0  var(--side-padding);
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    @media (max-width: 680px) {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        .c .txt {
            margin-bottom: 2rem;
        }
        &.revert {
            display: flex;
            flex-direction: column-reverse;
          
        }
    }
    .c {
        position: relative;
        .txt {
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            @media (max-width: 680px) {
                position: relative;
                transform: unset;
                top: unset;
            }
        }
    }
    p {
        font-size: 1em;
        color: $dark;
    }
}

.img-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
    // display: none;
  }

.navigation-home {
    width: 100%;
    display: grid;
    gap: 0;
    margin-bottom: 4rem;
    a {
        width: 100%!important;
        padding: 0 10px;
    }
    .block, .block-big {
        @include aspect-ratio(2,1);
        .i {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
            }
        }
     }

}

.navigation, .navigation-big {
    display: grid;
    gap: 0;
    @media (max-width: 680px) {
        grid-template-columns: 1fr 1fr;
      }
    .block, .block-big {
        @include aspect-ratio(1,1);
        .i {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
            }
        }
        a {
            @include transition(all);
            border-radius: 0;
            background-color: $primary;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            color: #fff;
            &.dark {
                background-color: $dark;
            }
            &.light {
                background-color: $light;
            }
            &.ocre {
                background-color: $ocre;
            }
            &:hover {
                border-radius: 100%;
                .image-blend {
                    border-radius: 100%;
                    img {
                        border-radius: 100%;
                    }
                }
            }
            span {
                position: absolute;
                width: 100%;
                text-align: center;
                font-family: 'Butler';
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: -0.02em;
                font-size: 2em;
                z-index: 1;
                left: 0;
                top: 50%;
                @include transform(translateY(-50%));
                p {
                    font-size: 1rem;
                    text-transform: initial;
                    margin-top: 1rem;
                }
            }
            img {
                width: 100%;
                position: relative;
                z-index: 0;
            }
        }
    }
}

.block-big {
    .b-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
    }
    .block {
        position: relative;
        float: left;
        width: 50%;
    }
}

.nous-connaitre, .nos-services, .rse, .contact {
    // margin-top: 4rem;
    @media (max-width: 680px) {
        .p-content {
            margin-bottom: 2rem;
        }
    }
}

#page.contact {
    .form-block {
        color: #fff;
        background-color: #273a55;
        border-radius: 1em 1em 0 0;
        margin-bottom: 0;
        padding: 2rem 4rem 4rem;
        .form {
            max-width: 540px;
            margin: 0 auto;
            display: flow-root;
            padding-top: 2rem;
            #submit {
                position: relative;
                float: right;
                margin-top: 2rem;
                height: 3rem;
                background: none;
                border: 2px solid #fff;
                border-radius: 8px;
                text-transform: uppercase;
                padding: 1rem;
                font-weight: 600;
                color: #fff;
                @include transition(all);
                &:hover {
                    color: $primary;
                    background-color: #fff;
                }
            }
        }
    }
}

main {

}

.p-content {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 740px;
    margin-bottom: 4rem;
    p {
        font-size: 1em;
        color: $dark;
    }
    b {
        color: $primary;
    }
    @media (max-width: 680px) {
       padding: 0 10px;
    }  
}




.block-content {
    @media (max-width: 680px) {
        &.revert {
            display: flex;
            flex-direction: column-reverse; 
            width: 100%;
        }
    }
    .i {
        @include aspect-ratio(1,1);
        @media (max-width: 680px) {
            &:before {
                display: none;
            }
            .big {
                position: relative!important;
                top: unset!important;
                transform: unset!important;
                display: flex;
            }
         }  
    }
    .bold .txt {
        color: $primary;
        font-weight: bold;
    }
}

.cta {
    .i {
        @include aspect-ratio(2,1);
        @media (max-width: 680px) {
            &:before {
                display: none;
            }
           } 
    }
}

.block-content, .cta {
    .i {
        width:50%;
        float: left;
        @media (max-width: 680px) {
           width:100%;
          }  
        &.primary {
            background-color: $primary;
            color: #fff;
        }
        &.dark {
            background-color: $dark;
            color: #fff;
        }
        &.light {
            background-color: $light;
            color: #fff;
        }
        &.ocre {
            background-color: $ocre;
            color: #fff;
        }
        .c {
            width: 100%;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            @media (max-width: 680px) {
                position: relative;
                float: left;
                top: unset;
                transform: unset;

              }
            &.txt {
                padding: 0 4rem;
                @media (max-width: 680px) {
                    padding: 4rem 10px;
                    position: relative;
                    float: left;
                    top: unset;
                    transform: unset;
                  }  
            }
            img {
                position: relative;
                float: left;
                width: 50%;
            }
            &.big {
                background-color: $ocre;
                &.primary {
                    background-color: $primary;
                }
                &.light {
                    background-color: $light;
                }
                &.dark {
                    background-color: $dark;
                }
                img {
                    padding: 4rem;
                    width: 100%;
                    @media (max-width: 680px) {
                        padding: 1.5rem;
                      }
                }
                &.noBorder {
                    img {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.content-home {
    h2 {
        position: absolute;
        z-index: 1;
        top: 8rem;
        color: #FFF;
        padding: 0 4rem;
        font-family: "Butler";
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-size: 2rem;
        line-height: initial;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
        @include transition(all);
        opacity: 1;
        &.active {
            opacity: 0;
        }
    }
    h3 {
        margin-bottom: 0;
    }
    a {
        position: absolute;
        top: 50%;
        width: fit-content;
        left: 50%;
        @include transform(translate(-50%,-50%));
        @include transition(all);
        color: #fff;
        text-align: center;
        padding: 4rem 0;
        .action--arrow-white {
            pointer-events: auto;
            z-index: 1000;
            height: 4rem;
            opacity: 0;
            position: absolute;
            bottom: -2rem;
            left: 50%;
            transform: translateX(-50%);
            svg {
                transform: rotate(180deg);
            }
        }
        &:hover {
            color: #000;
            top: calc(50% - 2rem);
            .action--arrow-white {
                opacity: 1;
            }
        }

    }
}

.img-container {
    position: relative;
    float: left;
    width: 100%;
    img {
        width: 100%;
        position: relative;
        display: block;
    }
}

.fullBlend {
    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        mix-blend-mode: multiply;
        -webkit-filter: grayscale(100%) contrast(1.2);
        filter: grayscale(100%) contrast(1);
    
    }

}

.image-blend {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    @include transition(all);
    &.active {
        opacity: .7;
        img {
            // filter: blur(5px); /* Adjust the blur intensity as desired */
        }
    }
    img {
        @include transition(all);
    }
    &.dark{
        background-color: $dark;
      }
      &.dark img{
         mix-blend-mode: multiply;
        -webkit-filter: grayscale(100%) contrast(1.2);
        filter: grayscale(100%) contrast(1);
      }
      &.dark::after{
        background-color: #000;
        mix-blend-mode: lighten;
      }

      &.primary{
        background-color: #536a8a;
      }
      &.primary img{
         mix-blend-mode: multiply;
        -webkit-filter: grayscale(100%) contrast(1.2);
        filter: grayscale(100%) contrast(1);
      }
      &.primary::after{
        background-color: $light;
        mix-blend-mode: lighten;
      }

      &.light{
        background-color: $light;
      }
      &.light img{
         mix-blend-mode: multiply;
        -webkit-filter: grayscale(100%) contrast(1.2);
        filter: grayscale(100%) contrast(1);
      }
      &.light::after{
        background-color: #000;
        mix-blend-mode: lighten;
      }

      &.ocre{
        background-color: $ocre;
      }
      &.ocre img{
         mix-blend-mode: multiply;
        -webkit-filter: grayscale(100%) contrast(1.2);
        filter: grayscale(100%) contrast(1);
      }
      &.ocre::after{
        background-color: #000;
        mix-blend-mode: lighten;
      }
}

