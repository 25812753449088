$primary: #273a55;
$light: #7A93AC;
$dark: #617073;
$ocre: #F8CBAD;


@mixin menu($transition) {
	-moz-transition: $transition .8s ease-in-out;
	-o-transition: $transition .8s ease-in-out;
	-ms-transform: $transition .8s ease-in-out;
	-webkit-transform: $transition .8s ease-in-out;
	transition: $transition .8s ease-in-out;
  }

header {
    position: relative;
    display: block;
    width: 100%;
    z-index: 2;
	height: 4rem;
	margin-bottom: 2rem;
	padding: 0  var(--side-padding);
	h1 {
		margin: 0;
		line-height: 4rem;
		font-weight: 900;
		z-index: 9;
    position: absolute;
		svg {
			height: 4rem;
			width: auto;
			path {
				fill: $primary;
				fill: #fff;

				@include transition(all)
			}
		}
		&.contact-title {
			svg {
				path {
					fill: $primary;
					@include transition(all)
				}
			}
		}
	}
}

.inline-menu {
	a {
		color:#fff;
		text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
	}
}

.inline-menu-contact {
	a {
		color: $primary;
		text-shadow: unset;
	}
}

.linkedIn {
	position: absolute;
    right: 5rem;
    width: 2rem;
    top: 1rem;
	svg {
		width: 2rem;
		height: auto;
		path {
			fill: #fff;
			@include transition(all);
		}
	}
}

.linkedInContact {
	path {
		fill:$primary!important;
	}
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

/* Icons */
.icon {
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin: 0 auto;
	fill: currentColor;
}

main {
	position: relative;
	width: 100%;
}

.frame {
	position: relative;
	z-index: 199;
}

.content {
	text-align: center;
	position: relative;
	z-index: 100;
}

.background {
	perspective: 1000px;
}

.background,
.background__copy {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-position: 50% 0%;
	background-size: cover;
	z-index: 1;
}

.background__copy {
	opacity: 0.3;
	transform: translate3d(0,0,0);
}



.action {
	background: none;
	border: 0;
	cursor: pointer;
	padding: 0;
}

.action--arrow {
	pointer-events: auto;
    position: absolute;
    top: -.28rem;
    right: -4rem;
    z-index: 1000;
    height: 4rem;
	@include transform(translateY(-50%) rotate(180deg));
}

.action--menu {
	pointer-events: auto;
    position: absolute;
    top: 0;
    right: 0.5rem;
    z-index: 1000;
	border-radius: 100%;
    height: 3rem;
    top: 0.5rem;
    padding: 0.25rem;
	background-color: $primary;
}

.menu--open .action--menu {
	pointer-events: none;
}

.action--close {
	position: absolute;
	top: 1.5rem;
	right: 1.75rem;
	z-index: 1000;
	opacity: 0;
	padding: 0 0.5rem;
}

.icon--menu {
	width: 2.5rem;
	fill: #fff;
}

.icon--close {
	width: 1rem;
}

#menu-container {
		@include menu(all);
		@include transform(translateY(-200%));
		position: fixed;
		top: 0;
		width: 100%;
		height: auto;
		z-index: 8;
		padding: var(--top-padding) var(--side-padding);
		text-transform: uppercase;
		background-color: #fff;
		height: 100%;
		h1 {
			position: absolute;
			top: 0;
			left: 4rem;
			margin: 0;
			line-height: 4rem;
			font-weight: 900;
			img {
				height: 4rem;
			}
			@media (max-width: 680px) {
				left: 10px;
			}
		}
		ul {

			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));
		  margin: 0;
		  padding: var(--top-padding) 0;
		  list-style: none;
		  display: grid;
		  grid-template-columns: 1fr 1fr 1fr 1fr;
		  gap: 2rem;
		  max-width: calc(100% - 8rem);
		  @media (max-width: 680px) {
			display: initial;
			max-width: calc(100% - 20px);
		}
		  li {
			text-align: center;
			width: 260px;
			max-width: 100%;
			.menu-image {
				background-color: $primary;
				@include aspect-ratio(1,1);
				border-radius: 100%;
				@media (max-width: 680px) {
					display: none;
				}
				&.dark {
					background-color: $dark;
				}
				&.light {
					background-color: $light;
				}
				&.ocre {
					background-color: $ocre;
				}
				img {
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
					height: auto;
					border-radius: 100%;
				}	
			}
			span {
				font-family: 'Butler';
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: -0.02em;
				font-size: 2em;
				margin-top: 2rem;
				display: block;
			}
		  }
		}	
}

.inline-menu {
    line-height: 4rem;
    list-style: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    width: calc(100% - 8rem);
    position: absolute;
    margin: 0;
    padding: 0;
    text-align: center;
    top: 0;
	li {
		display: inline-block;
		margin:0 1rem;
		a {
			@include transition(all)
		}
		a:hover {
			color: $light;
		}
	}
}

.menu {
	text-align: center;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: repeat(3,33.33%);
	pointer-events: none;
}

.menu--open {
	pointer-events: auto;
}

